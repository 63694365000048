import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import axios from "axios";
import { Typeahead } from "react-bootstrap-typeahead";
import Price from "./price";

class HeroImage2 extends Component {
  // Definieren Sie den gemeinsamen Graphen
  defaultGraph =
    "data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22160%22%20height%3D%2240%22%3E%20%3Cpath%20fill%3D%22none%22%20stroke%3D%22%2350cd89%22%20stroke-width%3D%221.5%22%20d%3D%22M20%2C20L20.267%2C20L...Z%22%2F%3E%3C%2Fsvg%3E";

  state = {
    commodities: [
      {
        title: "Testrohstoff",
        link: "https://app.rawbids.com/commodity?id=6294827f39723749",
        commodityPrice: { price: 135.03 },
        change: 3.62,
        graph: this.defaultGraph,
      },
      // 20 Weitere Testrohstoffe mit demselben graph
      {
        title: "Beta-Alanin",
        link: "https://app.rawbids.com/commodity?id=6294827f39723750",
        commodityPrice: { price: 89.99 },
        change: -2.15,
        graph: this.defaultGraph,
      },
      {
        title: "Glutamin",
        link: "https://app.rawbids.com/commodity?id=6294827f39723751",
        commodityPrice: { price: 45.5 },
        change: 1.25,
        graph: this.defaultGraph,
      },
      {
        title: "BCAA 3:1:2",
        link: "https://app.rawbids.com/commodity?id=6294827f39723752",
        commodityPrice: { price: 120.75 },
        change: -5.5,
        graph: this.defaultGraph,
      },
      // ... (Fügen Sie hier die restlichen 16 Testrohstoffe hinzu, alle mit graph: this.defaultGraph)
      {
        title: "L-Theanin",
        link: "https://app.rawbids.com/commodity?id=6294827f3972375B",
        commodityPrice: { price: 40.0 },
        change: 1.5,
        graph: this.defaultGraph,
      },
      // Weitere Commodities...
    ], // Insgesamt 21 Commodities
    isLoading: false,
    error: null,
  };

  fetchCommodities = async () => {
    const apiKey = "AZu2KThgcikY0bJQqiHXIW4TL4TO5XbVeVSEv1JP42ZtNswif7ciSNjjenT5vjCu";

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
      "Accept-Encoding": "gzip,deflate,compress",
      apiKey,
    };

    this.setState({ isLoading: true, error: null });

    try {
      const result = await axios.get(
        `https://cors-anywhere.herokuapp.com/https://eu-central-1.aws.data.mongodb-api.com/app/rawbids-prod-dvvxs/endpoint/getCommodities?onlyBasic=true`,
        { headers }
      );

      if (result.status === 200 && Array.isArray(result.data)) {
        // Mappt die API-Daten zu { title, link, commodityPrice, change, graph }
        const mappedData = result.data.map((item) => ({
          title: item.title.en,
          link: `https://app.rawbids.com/commodity?id=${item.articleId}`,
          commodityPrice: item.commodityPrice, // { price: 135.03 }
          change: item.change, // z.B. 3.62
          graph: `data:image/svg+xml;utf8,${encodeURIComponent(item.svg)}`, // Sicher codiert
        }));
        this.setState({ commodities: mappedData, isLoading: false });
      } else {
        console.error("Fehler beim Abrufen der Commodities:", result.statusText);
        this.setState({ isLoading: false, error: "Fehler beim Laden der Daten." });
      }
    } catch (error) {
      console.error("Fehler beim Abrufen der Commodities:", error.message);
      this.setState({ isLoading: false, error: "Fehler beim Laden der Daten." });
      // Optional: Weitere Maßnahmen bei Fehlern
    }
  };

  componentDidMount() {
    this.fetchCommodities();
  }

  handleSelection = (selected) => {
    if (selected.length > 0) {
      const selectedOption = selected[0];
      window.open(selectedOption.link, "_blank");
    }
  };

  render() {
    const { t } = this.props;
    const { commodities, isLoading, error } = this.state;

    return (
      <>
        <section
          className="big-section cover-background mt-0 mb-4 mx-1 mx-sm-3"
          style={{
            borderRadius: 30,
            margin: 20,
            marginTop: 5,
          }}
        >
          <div
            className="opacity-extra-medium-2 bg-dark-slate-blue"
            style={{ opacity: 0.5, backgroundColor: "#151b2a" }}
          />
          <div className="container">
            <div className="row pt-5 pb-5">
              <div className="col-12 col-xl-12 col-lg-12 col-md-12 overlap-gap-section pb-5">
                <h2
                  className="alt-font text-white font-weight-600 wow animate__fadeIn text-uppercase mb-2 herotitle"
                  style={{ visibility: "visible", animationName: "fadeIn" }}
                >
                  {t("title353")}
                </h2>
                <h2
                  className="alt-font text-white font-weight-600 wow animate__fadeIn mb-3 text-uppercase herotitle"
                  style={{ visibility: "visible", animationName: "fadeIn" }}
                >
                  {t("title354")}
                </h2>
                <h6
                  className="text-white alt-font text-uppercase letter-spacing-1px text-uppercase wow animate__fadeIn herosubtitle"
                  style={{ visibility: "visible", animationName: "fadeIn" }}
                >
                  {t("title355")}
                </h6>
              </div>
              <div className="col-12 col-lg-6">
                <div className="">
                  <div className="input-group p-3 bg-white" style={{ borderRadius: 8 }}>
                    <Typeahead
                      id="custom-typeahead"
                      options={commodities}
                      labelKey="title"
                      placeholder="Vitamins, Minerals, Plant Extracts,..."
                      onChange={this.handleSelection}
                      size="lg"
                      className="form-control form-control-lg p-0 border-none border-0"
                      renderMenuItemChildren={(option) => <div>{option.title}</div>}
                      isLoading={isLoading}
                      disabled={isLoading}
                    />
                    <button
                      className="btn btn-neon-orange btn-round-edge px-3"
                      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                      onClick={() => this.fetchCommodities()}
                      disabled={isLoading}
                    >
                      <i className="fa fa-search d-inline" style={{ marginRight: 5, color: "inherit" }}></i>
                      {t("title356")}
                    </button>
                  </div>
                </div>
                {error && (
                  <div className="alert alert-danger" role="alert">
                    {error}
                  </div>
                )}
                <div>
                  <span
                    className="text-white"
                    style={{ marginBottom: 0, marginTop: 5, paddingBottom: 0, fontWeight: 500, marginRight: 3 }}
                  >
                    {t("title357")}:
                  </span>
                  <div
                    className="badge badge-dark badge-lg py-1 px-2 mt-0 mx-1"
                    style={{ background: "rgba(255, 255, 255, 0.3)", paddingTop: 0, marginTop: 0 }}
                  >
                    Green Tea Extract
                  </div>
                  <div
                    className="badge badge-dark badge-lg py-1 px-2 mt-0 mx-1"
                    style={{ background: "rgba(255, 255, 255, 0.3)", paddingTop: 0, marginTop: 0 }}
                  >
                    BCAA 2:1:1
                  </div>
                  <div
                    className="badge badge-dark badge-lg py-1 px-2 mt-0 mx-1"
                    style={{ background: "rgba(255, 255, 255, 0.3)", paddingTop: 0, marginTop: 0 }}
                  >
                    Acerola Extract
                  </div>
                  <div
                    className="badge badge-dark badge-lg py-1 px-2 mt-0 mx-1"
                    style={{ background: "rgba(255, 255, 255, 0.3)", paddingTop: 0, marginTop: 0 }}
                  >
                    Vitamin C
                  </div>
                </div>
              </div>
            </div>
          </div>
          <video loop autoPlay muted className="html-video herovideo" id="video-10" playsInline>
            <source
              type="video/mp4"
              src="https://previews.customer.envatousercontent.com/304befac-d288-4986-b1bd-a2878e63799b/watermarked_preview/watermarked_preview.mp4"
            />
          </video>
        </section>
        <div className="header align-items-stretch" style={{ height: 40 }}>
          <div className="marquee" style={{ width: "100%", padding: 2 }}>
            <div
              className="header-animation"
              style={{ animation: "45s linear 0s infinite normal none running marquee" }}
            >
              {commodities.map((item) => (
                <Price
                  key={item.link} // Verwenden Sie einen eindeutigen Schlüssel, z.B. 'articleId' falls verfügbar
                  name={item.title}
                  price={`${item.commodityPrice.price.toFixed(2)}€`}
                  percent={`${item.change > 0 ? "+" : ""}${item.change.toFixed(2)}%`}
                  graph={item.graph} // Direkter Data-URL
                  color={item.change >= 0 ? "success" : "danger"}
                />
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation("general")(HeroImage2);
