import React, { Component } from "react";
import { ReactSVG } from "react-svg";
import { withTranslation } from "react-i18next";

class Footer extends Component {
  state = {};
  render() {
    const { t } = this.props;
    return (
      <footer className="footer-dark bg-extra-dark-gray-2">
        <div className="footer-top padding-five-tb lg-padding-eight-tb md-padding-50px-tb">
          <div className="container">
            <div className="row">
              <div className="col-12 mb-5 col-md-3">
                <h6 className="text-white">{t("title207")}</h6>
                <p className="text-white mb-0">Rawbids GmbH</p>
                <span>
                  Willy-Brandt-Straße 23
                  <br />
                  20457 Hamburg
                  <br />
                  Germany
                </span>
              </div>{" "}
              <div className="col-6 col-md-3">
                <h6 className="text-white">{t("title208")}</h6>
                <p className="text-white mb-0">Rawbids USA</p>
                <span>
                  80 Broad Street
                  <br />
                  10004 New York
                  <br />
                  USA
                </span>
              </div>
              <div className="col-6 col-md-3">
                <h6 className="text-white">&nbsp;</h6>
                <p className="text-white mb-0">Rawbids Asia</p>
                <span>
                  168 Middle Xizang Road
                  <br />
                  200001 Shanghai
                  <br />
                  China
                </span>
              </div>{" "}
              <div className="col-6 col-md-3">
                <h6 className="text-white">&nbsp;</h6>
                <p className="text-white mb-0">Rawbids Europe</p>
                <span>
                  Willy-Brandt-Straße 23
                  <br />
                  20457 Hamburg
                  <br />
                  Germany
                </span>
              </div>
            </div>
            <div className="row mt-5 ">
              {/* start footer column */}
              <div className="col-6 col-md-3">
                <h6 className="text-white">{t("title217")}</h6>
                <a href={"/"}>{t("title209")}</a>
                <br />
                <a href={"/quality"}>{t("title210")}</a>
                <br /> <a href={"/imprint"}>{t("title211")}</a>
                <br /> <a href={"/imprint"}>{t("title212")}</a>
                <br /> <a href={"/privacy"}>{t("title213")}</a>
                <br />
              </div>{" "}
              <div className="col-6 col-md-3">
                <h6 className="text-white">{t("title214")}</h6>
                <a href={"/investor"}>{t("title215")}</a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom padding-40px-tb border-top border-color-white-transparent">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-md-3 text-center text-md-left sm-margin-20px-bottom">
                <a href="#" className="footer-logo">
                  {/*  <img
                    src="images/logo-white.png"
                    data-at2x="images/logo-white@2x.png"
                    alt=""
                  /> */}
                  <a className="navbar-brand" href="/">
                    <img
                      src="images/logo-light.png"
                      data-at2x="images/logo-light.png"
                      className="default-logo"
                      alt=""
                    />
                  </a>
                </a>
              </div>
              <div className="col-12 col-md-9 d-flex justify-content-between align-items-center sm-margin-20px-bottom">
                <span>© {new Date().getFullYear()} Rawbids GmbH</span>
                <span className="text-white">DE-ÖKO-006</span>
              </div>
              <div className="col-12 col-md-3 text-center text-md-right">
                <div className="social-icon-style-12">
                  <ul className="extra-small-icon light d-none">
                    <li>
                      <a className="xing" href="#" target="_blank">
                        <i className="fab fa-xing" />
                      </a>
                    </li>
                    <li>
                      <a className="linkedin" href="#" target="_blank">
                        <i className="fab fa-linkedin" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default withTranslation("general")(Footer);
