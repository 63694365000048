import React, { Component } from "react";

import HeroImage from "../sections/heroImage";

import FinalSummary from "../sections/finalSummary";
import Contact from "../sections/contact";
import Summary from "../sections/summary";
import Compostable from "../sections/compostable";
import Coins from "../sections/coins";
import Stats from "../sections/stats";
import Dark from "../sections/dark";
import ImageSlider from "../sections/imageSlider";
import Configurator from "../sections/configurator";
import HeroImage2 from "../sections/heroImage2";
class HomePage extends Component {
  state = {};

  render() {
    return (
      <React.Fragment>
        {/*<HeroImage showLogin={this.props.showLogin} />*/}
        <HeroImage2 showLogin={this.props.showLogin} />
        {/*<Stats />*/}
        <Coins showCalendar={this.props.showCalendar} />
        {/* <Summary />

        <Dark />
        <ImageSlider />
        <Configurator />
       <Compostable />*/}
        <FinalSummary showLogin={this.props.showLogin} />

        <span className="scroll-top-arrow">
          <i className="feather icon-feather-arrow-up text-extra-dark-gray" />
        </span>
      </React.Fragment>
    );
  }
}

export default HomePage;
