import React, { Component } from "react";

class Price extends Component {
  render() {
    return (
      <>
        <div className="d-inline-flex text-center background-dark bg-light border-radius-10px mx-2 p-2 pr-0 pl-4">
          <div className="d-inline false" style={{ lineHeight: "16px" }}>
            <a
              className="custom-link text-black fw-bolder text-ellipsis"
              href={this.props.link}
              style={{ fontSize: "11px", width: "100px" }}
            >
              {this.props.name.substring(0, 30)}
            </a>
            <div>
              <span className="text-black fw-bolder float-right" style={{ fontSize: "16px" }}>
                <span
                  className={`ml-1 float-right priceticker-percentage ${
                    this.props.color === "success" ? "text-success" : "text-danger"
                  } font-weight-500`}
                  style={{ fontSize: "12px" }}
                >
                  {this.props.percent}
                </span>
                <span
                  className="fs-6 priceticker-price font-weight-600 float-right"
                  title={`Exact price: ${this.props.price}`} // Tooltip anzeigen
                >
                  {this.props.price}
                </span>
              </span>
            </div>
          </div>

          <div>
            <div id="chart" style={{ marginLeft: "7px" }}>
              {/* Verwende ein img-Tag */}
              <img src={this.props.graph} alt="price graph" style={{ width: "160px", height: "40px" }} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Price;
