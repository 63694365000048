import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class FinalSummary extends Component {
  state = {};

  render() {
    const { t, showLogin } = this.props;
    return (
      <section className="bg-white py-0">
        <div className="container">
          <div className="row">
            {/* Linke Spalte: Text */}
            <div className="col-12" style={{ marginTop: 240, marginBottom: 240 }}>
              <div
                className="wow animate__fadeInLeft text-center"
                data-wow-delay="0.5s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "fadeInLeft",
                }}
              >
                <p className="text-extra-large-2 text-extra-dark-gray font-weight-500 margin-5px-bottom d-block text-center ">
                  <span className="font-weight-700">{t("title206")}</span>
                </p>
                <h4 className="alt-font-2 font-weight-600 text-extra-dark-gray w-100 mb-4 text-center ">
                  {t("text34")}
                </h4>
                <button
                  className="btn btn-neon-orange btn-round-edge btn-lg px-3 mt-5"
                  type="button"
                  onClick={() => this.props.showLogin()}
                >
                  Register now
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation("general")(FinalSummary);
