import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class Coins extends Component {
  state = { view: 1 };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <section id="about mb-0 pb-0">
          <div className="">
            <div className="container">
              <div className="row">
                <div className="col-12 col-xl-6 ">
                  <div className="cont">
                    <h3 className="alt-font font-weight-600 text-extra-dark-gray  ">{t("title358")}</h3>
                    <p className="mt-5 text-extra-dark-gray text-extra-large  margin-5px-bottom d-block">
                      {t("text48")}
                    </p>
                  </div>
                </div>{" "}
                <div className="col-12 col-xl-6 ">
                  {" "}
                  <div className="row ml-4">
                    <h5 className="mb-3 mt-0 text-extra-large-2 text-extra-dark-gray font-weight-500 margin-5px-bottom d-block featurelist d-block">
                      <i aria-hidden="true" className="fas fa-check text-orange d-initial"></i>&nbsp;&nbsp;
                      {t("title359")}
                    </h5>{" "}
                    <h5 className="my-3 text-extra-large-2 text-extra-dark-gray font-weight-500 margin-5px-bottom d-block featurelist d-block">
                      <i aria-hidden="true" className="fas fa-check text-orange d-initial"></i>&nbsp;&nbsp;
                      {t("title360")}
                    </h5>{" "}
                    <h5 className="my-3 text-extra-large-2 text-extra-dark-gray font-weight-500 margin-5px-bottom d-block featurelist d-block">
                      <i aria-hidden="true" className="fas fa-check text-orange"></i>&nbsp;&nbsp;{t("title361")}
                    </h5>{" "}
                    <h5 className="my-3 text-extra-large-2 text-extra-dark-gray font-weight-500 margin-5px-bottom d-block featurelist d-block">
                      <i aria-hidden="true" className="fas fa-check text-orange"></i>&nbsp;&nbsp;{t("title362")}
                    </h5>{" "}
                    <h5 className="my-3 text-extra-large-2 text-extra-dark-gray font-weight-500 margin-5px-bottom d-block featurelist d-block">
                      <i aria-hidden="true" className="fas fa-check text-orange"></i>&nbsp;&nbsp;{t("title363")}
                    </h5>{" "}
                    <h5 className="my-3 text-extra-large-2 text-extra-dark-gray font-weight-500 margin-5px-bottom d-block featurelist d-block">
                      <i aria-hidden="true" className="fas fa-check text-orange"></i>&nbsp;&nbsp;{t("title364")}
                    </h5>
                    <div className="d-block w-100 mt-5">
                      <button
                        onClick={() => (window.location.href = "https://app.rawbids.com/demo")}
                        className="btn btn-outline-warning btn-round-edge btn-lg px-3"
                        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                      >
                        {t("title365")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>{" "}
        <section className="text-center">
          <video autoPlay controls="" muted playsInline loop>
            <source type="video/mp4" src="images/RB_Greenscreen_LQ.mp4" />
          </video>
        </section>
        <section className="bg-white">
          <div className="container">
            <div className="row justify-content-center wow animate__fadeIn ">
              {" "}
              <div className="col-12 col-lg-12 col-md-10">
                <div
                  className="col-12 col-lg-10 col-md-10 md-margin-7-rem-bottom wow animate__fadeInLeft"
                  data-wow-delay="0.5s"
                >
                  <h2 className="alt-font font-weight-600 text-extra-dark-gray w-95 mb-4">{t("title145")}</h2>

                  <p className="text-extra-medium text-extra-dark-gray font-weight-400 margin-5px-bottom d-block mb-5 text-extra-large">
                    <span className="font-weight-700">{t("title146")}</span>
                    <br />
                    {t("text19")}
                  </p>
                </div>
              </div>{" "}
              <div className="col-12 col-lg-6 col-sm-9 text-center d-flex">
                <video
                  loop="true"
                  controls="true"
                  poster="images/eikonpreview.png"
                  className="border-radius-20px justify-content-center align-self-center d-none"
                >
                  <source type="video/mp4" src="images/eikon.mp4" />
                </video>
              </div>
            </div>{" "}
          </div>
        </section>{" "}
        <section className="overflow-visible bg-light-gray big-section mt-10">
          <div className="container mt-10">
            <div className="overlap-section" style={{ marginTop: "-256px" }}>
              <div className="bg-neon-orange border-radius-6px padding-4-half-rem-tb padding-4-rem-lr sm-no-padding-lr wow animate__pulse">
                <div className="row align-items-center justify-content-center">
                  <div className="col-10 col-xl-8 text-xl-start lg-margin-40px-bottom text-center text-xl-left">
                    <span className="text-extra-medium alt-font text-white">{t("title148")}</span>
                    <h6 className="alt-font font-weight-600 text-white letter-spacing-minus-1-half m-0">
                      {t("title149")}
                    </h6>
                  </div>

                  <div className="col-22 col-lg-3 text-center">
                    <button
                      className="btn btn-medium btn-dark-gray btn-fancy btn-round-edge section-link"
                      onClick={() => this.props.showCalendar()}
                    >
                      {t("title150")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-xl-7 col-lg-8 col-md-9 text-center margin-8-half-rem-top margin-5-half-rem-bottom md-margin-4-half-rem-bottom md-margin-7-rem-top wow animate__fadeIn">
                <span className="alt-font font-weight-500 text-medium text-neon-orange text-uppercase letter-spacing-1px d-block margin-25px-bottom">
                  {t("title151")}
                </span>
                <h4 className="alt-font font-weight-600 text-slate-blue letter-spacing-minus-1px">{t("title152")}</h4>
              </div>
            </div>
            <div className="row row-cols-1 row-cols-lg-3 row-cols-sm-2 justify-content-center">
              <div
                className="col md-margin-30px-bottom sm-margin-15px-bottom wow animate__fadeIn mb-4"
                data-wow-delay="0.2s"
              >
                <div className="cards2">
                  <div className="feature-box-icon">
                    <i className="feather icon-feather-check icon-large text-neon-orange margin-35px-bottom md-margin-15px-bottom sm-margin-10px-bottom"></i>
                  </div>
                  <div className="feature-box-content last-paragraph-no-margin">
                    <span className="alt-font font-weight-500 margin-10px-bottom d-block text-extra-dark-gray text-extra-medium">
                      {t("title153")}
                    </span>
                    <p>{t("text20")}</p>
                  </div>
                </div>
              </div>
              <div
                className="col md-margin-30px-bottom sm-margin-15px-bottom wow animate__fadeIn mb-4"
                data-wow-delay="0.2s"
              >
                <div className="cards2">
                  <div className="feature-box-icon">
                    <i className="feather icon-feather-check icon-large text-neon-orange margin-35px-bottom md-margin-15px-bottom sm-margin-10px-bottom"></i>
                  </div>
                  <div className="feature-box-content last-paragraph-no-margin">
                    <span className="alt-font font-weight-500 margin-10px-bottom d-block text-extra-dark-gray text-extra-medium">
                      {t("title154")}
                    </span>
                    <p>{t("text21")}</p>
                  </div>
                </div>
              </div>
              <div
                className="col md-margin-30px-bottom sm-margin-15px-bottom wow animate__fadeIn mb-4"
                data-wow-delay="0.2s"
              >
                <div className="cards2">
                  <div className="feature-box-icon">
                    <i className="feather icon-feather-check icon-large text-neon-orange margin-35px-bottom md-margin-15px-bottom sm-margin-10px-bottom"></i>
                  </div>
                  <div className="feature-box-content last-paragraph-no-margin">
                    <span className="alt-font font-weight-500 margin-10px-bottom d-block text-extra-dark-gray text-extra-medium">
                      {t("title155")}
                    </span>
                    <p>{t("text22")}</p>
                  </div>
                </div>
              </div>
              <div
                className="col md-margin-30px-bottom sm-margin-15px-bottom wow animate__fadeIn mb-4"
                data-wow-delay="0.2s"
              >
                <div className="cards2">
                  <div className="feature-box-icon">
                    <i className="feather icon-feather-check icon-large text-neon-orange margin-35px-bottom md-margin-15px-bottom sm-margin-10px-bottom"></i>
                  </div>
                  <div className="feature-box-content last-paragraph-no-margin">
                    <span className="alt-font font-weight-500 margin-10px-bottom d-block text-extra-dark-gray text-extra-medium">
                      {t("title156")}
                    </span>
                    <p>{t("text23")}</p>
                  </div>
                </div>
              </div>
              <div
                className="col md-margin-30px-bottom sm-margin-15px-bottom wow animate__fadeIn mb-4"
                data-wow-delay="0.2s"
              >
                <div className="cards2">
                  <div className="feature-box-icon">
                    <i className="feather icon-feather-check icon-large text-neon-orange margin-35px-bottom md-margin-15px-bottom sm-margin-10px-bottom"></i>
                  </div>
                  <div className="feature-box-content last-paragraph-no-margin">
                    <span className="alt-font font-weight-500 margin-10px-bottom d-block text-extra-dark-gray text-extra-medium">
                      {t("title157")}
                    </span>
                    <p>{t("text24")}</p>
                  </div>
                </div>
              </div>
              <div
                className="col md-margin-30px-bottom sm-margin-15px-bottom wow animate__fadeIn mb-4"
                data-wow-delay="0.2s"
              >
                <div className="cards2">
                  <div className="feature-box-icon">
                    <i className="feather icon-feather-check icon-large text-neon-orange margin-35px-bottom md-margin-15px-bottom sm-margin-10px-bottom"></i>
                  </div>
                  <div className="feature-box-content last-paragraph-no-margin">
                    <span className="alt-font font-weight-500 margin-10px-bottom d-block text-extra-dark-gray text-extra-medium">
                      {t("title158")}
                    </span>
                    <p>{t("text25")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="reading" className="p-0 wow animate__fadeIn d-none">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-xl-6 col-lg-5 cover-background md-h-550px sm-h-400px xs-h-300px wow animate__fadeIn bgimg"></div>
              <div
                className="col-12 col-xl-6 col-lg-7 padding-9-half-rem-tb padding-10-half-rem-lr bg-extra-dark-gray-2 xl-padding-2-rem-all  px-5 wow animate__fadeIn"
                data-wow-delay="0.3s"
              >
                <span className="alt-font font-weight-500 text-medium text-neon-orange text-uppercase letter-spacing-1px d-block margin-30px-bottom xs-margin-20px-bottom">
                  {t("title159")}
                </span>
                <h4 className="alt-font font-weight-500 text-white margin-5-half-rem-bottom lg-margin-4-half-rem-bottom letter-spacing-minus-1px w-90 xl-w-100 xs-margin-3-half-rem-bottom">
                  {t("title160")}
                </h4>
                <div
                  className="panel-group accordion-event accordion-style-05"
                  id="accordion1"
                  data-active-icon="fa-angle-down"
                  data-inactive-icon="fa-angle-right"
                >
                  <div className="panel cursorhand">
                    <div
                      className={
                        "panel-heading border-color-transparent pl-4  " +
                        (this.state.view === 1 ? "active-accordion" : "")
                      }
                    >
                      <span className="accordion-toggle ml-0 " onClick={() => this.setState({ view: 1 })}>
                        <div className="panel-title">
                          <span className="alt-font text-white d-inline-block font-weight-700 ">{t("title161")}</span>
                          <i className="indicator fas fa-angle-down text-white icon-extra-small"></i>
                        </div>
                      </span>
                    </div>
                    <div
                      id="collapseOne"
                      className={"panel-collapse collapse " + (this.state.view === 1 ? "show" : "")}
                    >
                      <div className="panel-body text-white pl-4">{t("title162")}</div>
                    </div>
                  </div>

                  <div className="panel cursorhand">
                    <div
                      className={
                        "panel-heading border-color-transparent pl-4  " +
                        (this.state.view === 2 ? "active-accordion" : "")
                      }
                    >
                      <span className="accordion-toggle ml-0" onClick={() => this.setState({ view: 2 })}>
                        <div className="panel-title">
                          <span className="alt-font text-white d-inline-block font-weight-700">{t("title163")}</span>
                          <i className="indicator fas fa-angle-down text-white icon-extra-small"></i>
                        </div>
                      </span>
                    </div>
                    <div
                      className={
                        "panel-heading border-color-transparent pl-4  " +
                        (this.state.view === 2 ? "active-accordion" : "")
                      }
                    >
                      {" "}
                      <div
                        id="collapseOne"
                        className={"panel-collapse collapse " + (this.state.view === 2 ? "show" : "")}
                      >
                        <div className="panel-body text-white pl-4">{t("text26")}</div>
                      </div>{" "}
                    </div>
                  </div>

                  <div className="panel cursorhand">
                    <div
                      className={
                        "panel-heading border-color-transparent pl-4  " +
                        (this.state.view === 3 ? "active-accordion" : "")
                      }
                    >
                      <span className="accordion-toggle ml-0" onClick={() => this.setState({ view: 3 })}>
                        <div className="panel-title">
                          <span className="alt-font text-white d-inline-block font-weight-700">{t("title164")}</span>
                          <i className="indicator fas fa-angle-down text-white icon-extra-small"></i>
                        </div>
                      </span>
                    </div>
                    <div
                      id="collapseThree"
                      className={"panel-collapse collapse " + (this.state.view === 3 ? "show" : "")}
                      data-bs-parent="#accordion1"
                    >
                      <div className="panel-body text-white pl-4">{t("text27")}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withTranslation("general")(Coins);
